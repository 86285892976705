export const logoNegative = ['690 134', `
  <title>Logo</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1330.53 283.2" style="enable-background:new 0 0 1330.53 283.2;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#FFFFFF;}
    .st1{fill:#5097F8;}
  </style>
  <g>
    <path class="st0" d="M90.84,16.19c3.74,5.2,5.62,11.43,5.62,18.68c0,7.47-1.89,13.48-5.67,18.01c-2.11,2.55-5.22,4.87-9.33,6.97
      c6.24,2.27,10.96,5.87,14.13,10.79c3.18,4.93,4.77,10.9,4.77,17.93c0,7.25-1.82,13.75-5.45,19.51c-2.31,3.82-5.2,7.03-8.67,9.63
      c-3.91,2.99-8.51,5.04-13.82,6.14c-5.31,1.11-11.07,1.66-17.29,1.66H0V3.15h59.13C74.05,3.38,84.62,7.72,90.84,16.19z M24.4,24.4
      v26.98h29.74c5.31,0,9.63-1.01,12.94-3.03c3.31-2.02,4.97-5.6,4.97-10.75c0-5.7-2.19-9.46-6.57-11.29
      c-3.78-1.27-8.6-1.91-14.46-1.91H24.4z M24.4,71.64v32.62h29.71c5.3,0,9.44-0.72,12.39-2.16c5.36-2.66,8.04-7.75,8.04-15.27
      c0-6.36-2.6-10.74-7.79-13.12c-2.9-1.33-6.98-2.02-12.23-2.08H24.4z"/>
    <path class="st0" d="M145.68,125.51h-25.4V3.15h25.4V125.51z"/>
    <path class="st0" d="M169.17,3.15h26.8l48.57,85.31V3.15h23.82v122.35H242.8l-49.81-86.81v86.81h-23.82V3.15z"/>
    <path class="st0" d="M327.34,3.15h28.93l43.29,122.35h-27.72l-8.07-25.15H318.7l-8.29,25.15h-26.74L327.34,3.15z M325.66,79.27
      h31.34l-15.46-48.14L325.66,79.27z"/>
    <path class="st0" d="M493.53,6.31c4.51,1.94,8.32,4.79,11.45,8.55c2.59,3.1,4.64,6.53,6.15,10.29c1.51,3.76,2.27,8.05,2.27,12.87
      c0,5.81-1.47,11.52-4.4,17.14c-2.93,5.62-7.78,9.59-14.53,11.91c5.64,2.27,9.64,5.49,11.99,9.67c2.35,4.18,3.53,10.56,3.53,19.13
      v8.22c0,5.59,0.22,9.38,0.67,11.37c0.67,3.15,2.25,5.48,4.72,6.97v3.07h-28.22c-0.78-2.71-1.33-4.9-1.66-6.56
      c-0.66-3.43-1.02-6.94-1.08-10.54l-0.17-11.37c-0.1-7.8-1.45-13-4.04-15.61c-2.59-2.6-7.45-3.9-14.57-3.9h-24.97v47.98H415.7V3.15
      h58.52C482.59,3.32,489.02,4.37,493.53,6.31z M440.69,24.4v32.87h27.51c5.47,0,9.56-0.66,12.3-1.99c4.84-2.32,7.25-6.92,7.25-13.78
      c0-7.42-2.34-12.4-7.02-14.94c-2.63-1.44-6.57-2.16-11.82-2.16H440.69z"/>
    <path class="st0" d="M601.47,3.15h28.97l-40.92,76.48v45.88h-25.57V79.63L521.62,3.15h30.13l25.4,53.29L601.47,3.15z"/>
    <path class="st0" d="M780.55,16.19c3.74,5.2,5.62,11.43,5.62,18.68c0,7.47-1.89,13.48-5.67,18.01c-2.11,2.55-5.22,4.87-9.33,6.97
      c6.24,2.27,10.96,5.87,14.13,10.79c3.18,4.93,4.77,10.9,4.77,17.93c0,7.25-1.82,13.75-5.45,19.51c-2.31,3.82-5.2,7.03-8.67,9.63
      c-3.91,2.99-8.51,5.04-13.83,6.14c-5.31,1.11-11.07,1.66-17.29,1.66h-55.13V3.15h59.13C763.76,3.38,774.33,7.72,780.55,16.19z
       M714.12,24.4v26.98h29.74c5.31,0,9.62-1.01,12.94-3.03c3.31-2.02,4.97-5.6,4.97-10.75c0-5.7-2.19-9.46-6.57-11.29
      c-3.78-1.27-8.6-1.91-14.46-1.91H714.12z M714.12,71.64v32.62h29.71c5.3,0,9.44-0.72,12.39-2.16c5.36-2.66,8.04-7.75,8.04-15.27
      c0-6.36-2.6-10.74-7.79-13.12c-2.9-1.33-6.98-2.02-12.23-2.08H714.12z"/>
    <path class="st0" d="M890.47,6.31c4.51,1.94,8.32,4.79,11.45,8.55c2.59,3.1,4.64,6.53,6.15,10.29c1.51,3.76,2.27,8.05,2.27,12.87
      c0,5.81-1.47,11.52-4.4,17.14c-2.93,5.62-7.78,9.59-14.53,11.91c5.64,2.27,9.64,5.49,11.99,9.67c2.35,4.18,3.53,10.56,3.53,19.13
      v8.22c0,5.59,0.22,9.38,0.67,11.37c0.67,3.15,2.25,5.48,4.72,6.97v3.07h-28.22c-0.78-2.71-1.33-4.9-1.66-6.56
      c-0.66-3.43-1.02-6.94-1.08-10.54l-0.17-11.37c-0.11-7.8-1.45-13-4.04-15.61c-2.59-2.6-7.45-3.9-14.57-3.9h-24.97v47.98h-24.99
      V3.15h58.52C879.53,3.32,885.97,4.37,890.47,6.31z M837.63,24.4v32.87h27.51c5.47,0,9.56-0.66,12.3-1.99
      c4.83-2.32,7.25-6.92,7.25-13.78c0-7.42-2.34-12.4-7.02-14.94c-2.63-1.44-6.57-2.16-11.83-2.16H837.63z"/>
    <path class="st0" d="M958.16,125.51h-25.4V3.15h25.4V125.51z"/>
    <path class="st0" d="M1053.76,5.81c8.58,2.82,15.53,8,20.85,15.52c4.26,6.09,7.17,12.67,8.72,19.76
      c1.55,7.08,2.33,13.83,2.33,20.25c0,16.27-3.27,30.05-9.8,41.34c-8.86,15.22-22.54,22.83-41.04,22.83h-52.75V3.15h52.75
      C1042.41,3.27,1048.72,4.15,1053.76,5.81z M1006.88,24.4v79.85h23.61c12.08,0,20.51-5.95,25.27-17.85
      c2.6-6.53,3.91-14.3,3.91-23.33c0-12.45-1.95-22.01-5.86-28.68c-3.91-6.67-11.68-10-23.32-10H1006.88z"/>
    <path class="st0" d="M1187.09,41.34c-1.94-8.36-6.67-14.19-14.19-17.51c-4.21-1.83-8.88-2.74-14.03-2.74
      c-9.85,0-17.94,3.71-24.28,11.14c-6.34,7.43-9.5,18.6-9.5,33.51c0,15.02,3.43,25.66,10.29,31.9c6.86,6.24,14.66,9.36,23.41,9.36
      c8.58,0,15.61-2.46,21.08-7.38c5.48-4.92,8.85-11.37,10.13-19.35h-28.31V59.85h50.97v65.66h-16.93l-2.57-15.27
      c-4.93,5.78-9.35,9.86-13.28,12.23c-6.75,4.13-15.05,6.2-24.9,6.2c-16.21,0-29.5-5.61-39.84-16.84
      c-10.79-11.28-16.19-26.71-16.19-46.29c0-19.8,5.45-35.67,16.35-47.62C1126.19,5.97,1140.61,0,1158.54,0
      c15.55,0,28.04,3.94,37.48,11.83c9.43,7.89,14.84,17.72,16.23,29.51H1187.09z"/>
    <path class="st0" d="M1327.54,24.82h-64.75V50.8h59.43v21.25h-59.43v31.46h67.73v22h-92.72V3.15h89.73V24.82z"/>
    <path class="st1" d="M392.65,268.51v13h-43.03c-7.63,0-14.17-1-19.63-2.99c-5.46-1.99-9.9-4.85-13.32-8.58
      c-3.42-3.73-5.94-8.21-7.54-13.46c-1.6-5.24-2.41-11.12-2.41-17.62v-12.22c0-6.5,0.8-12.37,2.41-17.62
      c1.6-5.24,4.12-9.73,7.54-13.46c3.42-3.73,7.86-6.58,13.32-8.58c5.46-1.99,12-2.99,19.63-2.99H392v13h-44.33
      c-9.01,0-15.38,2.15-19.11,6.44c-3.73,4.29-5.59,10.81-5.59,19.56v19.5c0,8.75,1.86,15.27,5.59,19.56
      c3.73,4.29,10.1,6.44,19.11,6.44H392.65z"/>
    <path class="st1" d="M499.9,240.56c0,6.42-0.85,12.24-2.54,17.49c-1.69,5.24-4.27,9.71-7.73,13.39c-3.47,3.69-7.91,6.54-13.33,8.58
      c-5.42,2.04-11.85,3.06-19.31,3.06h-13c-7.45,0-13.91-1.02-19.37-3.06c-5.46-2.04-9.92-4.9-13.39-8.58
      c-3.47-3.68-6.02-8.15-7.67-13.39c-1.65-5.24-2.47-11.07-2.47-17.49v-15.6c0-6.5,0.82-12.37,2.47-17.62
      c1.65-5.24,4.2-9.73,7.67-13.46c3.47-3.73,7.93-6.58,13.39-8.58c5.46-1.99,11.92-2.99,19.37-2.99h13c7.45,0,13.89,1,19.31,2.99
      c5.42,2,9.86,4.85,13.33,8.58c3.47,3.73,6.04,8.21,7.73,13.46c1.69,5.24,2.54,11.12,2.54,17.62V240.56z M417.35,244.07
      c0,8.67,1.91,15.17,5.72,19.5c3.81,4.33,10.14,6.5,18.98,6.5h16.9c8.93,0,15.27-2.17,19.04-6.5c3.77-4.33,5.66-10.83,5.66-19.5
      v-22.75c0-8.75-1.89-15.27-5.66-19.56s-10.12-6.44-19.04-6.44h-16.9c-8.84,0-15.17,2.15-18.98,6.44
      c-3.81,4.29-5.72,10.81-5.72,19.56V244.07z"/>
    <path class="st1" d="M518.75,281.51v-79.3c0-5.55,1.39-10.25,4.16-14.1c2.77-3.86,7.37-5.79,13.78-5.79c4.51,0,8.23,1.3,11.18,3.9
      c2.95,2.6,5.94,6.37,8.97,11.31l41.6,67.21c0.78,1.21,1.56,2.21,2.34,2.99c0.78,0.78,1.56,1.17,2.34,1.17
      c1.04,0,1.86-0.37,2.47-1.1c0.61-0.74,0.91-1.8,0.91-3.19v-80.6h14.95v80.21c0,5.72-1.45,10.31-4.35,13.78
      c-2.9,3.47-7.17,5.2-12.8,5.2c-4.77,0-8.6-1.26-11.51-3.77c-2.9-2.51-5.65-5.85-8.25-10.01l-42.51-68.25
      c-0.87-1.39-1.6-2.49-2.21-3.31c-0.61-0.82-1.43-1.24-2.47-1.24c-1.3,0-2.23,0.44-2.79,1.3c-0.56,0.87-0.85,2.04-0.85,3.51v80.08
      H518.75z"/>
    <path class="st1" d="M690.35,241.47l-24.7-6.76c-8.49-2.34-15.06-5.46-19.69-9.36c-4.64-3.9-6.96-9.49-6.96-16.77
      c0-7.28,2.75-13.19,8.25-17.74c5.5-4.55,13.89-6.83,25.15-6.83h45.5v13h-47.19c-6.24,0-10.55,1.08-12.94,3.25
      c-2.38,2.17-3.57,4.72-3.57,7.67c0,3.38,1.1,6.02,3.31,7.93c2.21,1.91,6.26,3.68,12.16,5.33l24.7,7.02
      c8.58,2.43,15.19,5.65,19.82,9.68c4.64,4.03,6.96,9.64,6.96,16.84c0,8.06-2.71,14.54-8.12,19.43c-5.42,4.9-13.67,7.35-24.76,7.35
      H641.6v-13h48.1c5.72,0,9.9-1.13,12.54-3.38c2.64-2.25,3.96-5.29,3.96-9.1c0-3.99-1.1-7-3.31-9.04
      C700.68,244.96,696.5,243.12,690.35,241.47z"/>
    <path class="st1" d="M831.65,240.56c0,6.42-0.84,12.24-2.54,17.49c-1.69,5.24-4.27,9.71-7.74,13.39
      c-3.47,3.69-7.91,6.54-13.32,8.58c-5.42,2.04-11.85,3.06-19.31,3.06h-13c-7.45,0-13.91-1.02-19.37-3.06
      c-5.46-2.04-9.92-4.9-13.39-8.58c-3.47-3.68-6.02-8.15-7.67-13.39c-1.65-5.24-2.47-11.07-2.47-17.49v-15.6
      c0-6.5,0.82-12.37,2.47-17.62c1.65-5.24,4.2-9.73,7.67-13.46c3.46-3.73,7.93-6.58,13.39-8.58c5.46-1.99,11.92-2.99,19.37-2.99h13
      c7.45,0,13.89,1,19.31,2.99c5.42,2,9.86,4.85,13.32,8.58c3.47,3.73,6.04,8.21,7.74,13.46c1.69,5.24,2.54,11.12,2.54,17.62V240.56z
       M749.1,244.07c0,8.67,1.91,15.17,5.72,19.5c3.81,4.33,10.14,6.5,18.98,6.5h16.9c8.93,0,15.28-2.17,19.04-6.5
      c3.77-4.33,5.66-10.83,5.66-19.5v-22.75c0-8.75-1.88-15.27-5.66-19.56c-3.77-4.29-10.12-6.44-19.04-6.44h-16.9
      c-8.84,0-15.17,2.15-18.98,6.44c-3.81,4.29-5.72,10.81-5.72,19.56V244.07z"/>
    <path class="st1" d="M850.5,281.51v-97.5h14.95v84.5h57.98v13H850.5z"/>
    <path class="st1" d="M937.73,281.51v-97.5h78.13v13h-63.18v28.47h63.18v13h-63.18v30.03h63.18v13H937.73z"/>
  </g>
  </svg>
`]
